export function validBirthDateRange() {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  // Now minus 100 years
  const min = new Date();
  min.setYear(today.getFullYear() - 100);

  // Now minus 10 years
  let max = new Date(today) ;
  max.setYear(max.getFullYear() - 10);

  return { min, max };
}

