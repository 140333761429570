import Vue from 'vue';
import VueCookies from "vue-cookies";

Vue.use(VueCookies);

//params check
const urlSearchParams = new URLSearchParams(window.location.search);
const paramsLang = urlSearchParams.get("lang");

//cookie check
const cookieLang = VueCookies.get("locale");

//navigator check
const browserLang = navigator.language.substring(0, 2);

const languageChoice = () => {
  if (paramsLang) {
    return paramsLang;
  } else if (cookieLang) {
    return cookieLang;
  } else if (browserLang === "en" || browserLang === "fr") {
    return browserLang;
  } else {
    return "fr";
  }
};

const defaultState = {
  bannerClosed: false,
  locale: languageChoice(),
};

const mutations = {
  changeLocale(state) {
    if (state.locale === "fr") {
      state.locale = "en";
      VueCookies.set("locale", "en", null, null, ".beneva.ca");
    } else if (state.locale === "en") {
      state.locale = "fr";
      VueCookies.set("locale", "fr", null, null, ".beneva.ca");
    } else {
      state.locale = "fr";
      VueCookies.set("locale", "fr", null, null, ".beneva.ca");
    }
  },
  closeBanner(state) {
    state.bannerClosed = true;
  },
};

export default {
  state: defaultState,
  mutations,
};