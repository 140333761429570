<script>
import { UnleashClient } from 'unleash-proxy-client';

export default {
  data() {
    return {
      maintenanceEnabled: false,
      cmsBannerDisabled: false,
      maintenanceMsg: '',
      supportedBrowserBannerEnabled: false,
    };
  },
  methods: {
    async initializeUnleash() {
      const unleashConfig = {
        appName: 'forgerock-platform-ui',
        url: process.env.VUE_APP_FEATURE_TOGGLE_URL,
        clientKey: process.env.VUE_APP_FEATURE_TOGGLE_CLIENT_KEY,
        metricsInterval: parseInt(process.env.VUE_APP_FEATURE_TOGGLE_METRIC_INTERVAL),
        refreshInterval: parseInt(process.env.VUE_APP_FEATURE_TOGGLE_REFRESH_INTERVAL),
      };

      const unleash = new UnleashClient(unleashConfig);

      unleash.on('error', console.error);
      unleash.on('update', () => {
        this.maintenanceEnabled = unleash.isEnabled(`forgerock.platformUI.maintenance.${process.env.VUE_APP_REALM}.enabled`);
        this.maintenanceMsg = unleash.getVariant(`forgerock.platformUI.maintenance.${process.env.VUE_APP_REALM}.enabled`);
        this.cmsBannerDisabled = unleash.isEnabled('forgerock.platformUI.cmsBanner.disabled');
        this.supportedBrowserBannerEnabled = unleash.isEnabled('forgerock.platformUI.supportedBrowserBanner.enabled');
      });

      await unleash.start();
    },
  },
  created() {
    this.initializeUnleash();
  },
};
</script>
